import config from '../settings/config';

const API_URL = 'https://' + config.api + '/nevipaver';
const WS_URL = 'wss://' + config.socket;


export const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;


export function Socket(url) {
  const socket = new WebSocket(WS_URL + url + '?x-nevia-auth=Bearer' + localStorage['login'].substring(7));
  socket.onopen = function(e) {
    console.log("SOCKET CONNECTED");
  }
  return socket;
}

export const timer = (time, data) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, time); 
  })
);

export const transformErrors = errors => {
  return errors.map(error => {
    if (error.schema.messages && error.schema.messages[error.name][error.argument]) {
      return {
        ...error,
        message: error.schema.messages[error.name][error.argument],
      };
    }
    return error;
  });
};

export function fetch(url, method='GET', data=null) {
  if (data != null) {
    data = JSON.stringify(data);
  }

  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'x-nevia-auth': localStorage['login']
  };
  
  return window.fetch(API_URL + url, {
    headers: headers,
    method: method,
    body: data,
    mode: 'cors',
  }).then(request => {
    if (!request.ok) {
      throw Error(request.status);
    }

    if (request.status !== 204) return request.json();

    return null;
  });
}

export function integerValue(value, defaultValue) {
  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return defaultValue;
  }

  return parsedValue;
}

export function floatValue(value, defaultValue) {
  if (value == null) return defaultValue;
  const newValue = parseFloat(value);

  return newValue;
}

function toRadians(angle) {
  return angle * (Math.PI / 180);
}

const Ca = 6378137.0;
const Cf = 1.0 / 298.257223563;
const Ck0 = 0.9996;
const Clo0 = toRadians(27.0);
const CE0 = 500000.0;
const Cn = Cf / (2.0 - Cf);
const CA1 = Ca / (1.0 + Cn) * (1.0 + (Math.pow(Cn, 2.0)) / 4.0 + (Math.pow(Cn, 4.0)) / 64.0);
const Ce = Math.sqrt((2.0 * Cf - Math.pow(Cf, 2.0)));
const Ch1p = 1.0 / 2.0 * Cn- 2.0/3.0 * Math.pow(Cn, 2.0) + 5.0/16.0 * Math.pow(Cn, 3.0) + 41.0 / 180.0 * Math.pow(Cn, 4.0);
const Ch2p = 13.0 / 48.0 * Math.pow(Cn, 2.0) - 3.0 / 5.0 * Math.pow(Cn, 3.0) + 557.0 / 1440.0 * Math.pow(Cn, 4.0);
const Ch3p = 61.0 / 240.0 * Math.pow(Cn, 3.0) - 103.0 / 140.0 * Math.pow(Cn, 4.0);
const Ch4p = 49561.0 / 161280.0 * Math.pow(Cn, 4.0);
const tm35fin_latitude_min = 6582464.0358;
const tm35fin_latitude_max = 7799839.8902;
const tm35fin_longitude_min = 50199.4814;
const tm35fin_longitude_max = 761274.6247;

export function toETRSTM35FIN(latitude, longitude) {
  const la = toRadians(latitude);
  const lo = toRadians(longitude);
  const Q = Math.asinh(Math.tan(la)) - Ce * Math.atanh(Ce * Math.sin(la));
  const be = Math.atan(Math.sinh(Q));
  const nnp = Math.atanh(Math.cos(be) * Math.sin(lo - Clo0));
  const Ep = Math.asin(Math.sin(be) * Math.cosh(nnp));
  const E1 = Ch1p * Math.sin(2.0 * Ep) * Math.cosh(2.0 * nnp);
  const E2 = Ch2p * Math.sin(4.0 * Ep) * Math.cosh(4.0 * nnp);
  const E3 = Ch3p * Math.sin(6.0 * Ep) * Math.cosh(6.0 * nnp);
  const E4 = Ch4p * Math.sin(8.0 * Ep) * Math.cosh(8.0 * nnp);
  const nn1 = Ch1p * Math.cos(2.0 * Ep) * Math.sinh(2.0 * nnp);
  const nn2 = Ch2p * Math.cos(4.0 * Ep) * Math.sinh(4.0 * nnp);
  const nn3 = Ch3p * Math.cos(6.0 * Ep) * Math.sinh(6.0 * nnp);
  const nn4 = Ch4p * Math.cos(8.0 * Ep) * Math.sinh(8.0 * nnp);
  const E = Ep + E1 + E2 + E3 + E4;
  const nn = nnp + nn1 + nn2 + nn3 + nn4;   
  const y = CA1* E * Ck0;
  const x = CA1* nn * Ck0+ CE0;

  if (y < tm35fin_latitude_min || y > tm35fin_latitude_max ||
      x < tm35fin_longitude_min || x > tm35fin_longitude_max) {
      return {x: 0, y: 0};
  }

  return {x: x, y: y};
}

export function stateValueParser(event, type, defaultValue) {
  let value;
  
  if (type === 'integer') {
    value = integerValue(event.target.value, defaultValue);
  }
  else if (type === 'float') {
    value = event.target.value.replace(',', '.');
    const regExp = /^\d*\.?\d*$/;

    if (!regExp.test(value)) {
      return undefined;
    }

    if (value.length === 2 && value[0] === '0') {
      if (value[1] !== '.') {
        value = value[1];
      }
    }
  }
  else if (type === 'boolean') {
    value = event.target.checked;
  }
  else {
    value = event.target.value;
  }

  return value;
}
