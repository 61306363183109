export const addLoads = loads => ({
  type: 'ADD_LOADS',
  loads
});

export const removeLoadById = load => ({
  type: 'REMOVE_LOAD',
  load
});

export const addLoad = load => ({
  type: 'ADD_LOAD',
  load
});

export const changeLoad = load => ({
  type: 'CHANGE_LOAD',
  load
});
