import React from 'react';
import { connect } from 'react-redux';
import { Excel, showMessage, showConfirm, showNotice } from 'components';
import * as XLSX from 'xlsx';
import { fromJS } from 'immutable';
import { stateValueParser, paddedNumber } from '../utils';
import './Load.css';


const CombineLoader = props => {
  if (!props.show) return null;

  return (
    <div className='modal'>
      <div id='combine-loader'>
        <h4>Ladataan...</h4>
        <h5>Älä sulje selainta</h5>
        <div className='loader' />
      </div>
    </div>
  );
};

class InputCoatingPlantData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFile: null,
      coatingPlantLoads: []
    };

    this.sendCoatingPlantData = this.sendCoatingPlantData.bind(this);
    this.clearExcel = this.clearExcel.bind(this);
  }

  componentDidUpdate (lastProps, lastState) {
    if (lastProps.timeRangeStart !== this.props.timeRangeStart ||
      lastProps.timeRangeEnd !== this.props.timeRangeEnd) {
      this.getSensorDevices();
    }
    if (lastState.selectedSensorDevice !== this.state.selectedSensorDevice) {
      this.getSensorValuesWithPositions();
    }
  }

  changeState (propertyName, type, defaultValue, event) {
    if (type === 'file') {
      const files = event.target.files;

      this.setState({
        [propertyName]: files[0]
      });

      return;
    }

    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    this.setState({ [propertyName]: value });

    if (typeof (Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  sendCoatingPlantData () {
    const reader = new FileReader();

    reader.onload = async function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets.Sheet1;

      let coatingPlantLoads = [];
      let time = [];
      let recipe = [];
      let temperature = [];
      let lastTruck;
      let TT;
      let site;
      let mass = 0;

      let massSum = 0;

      let currentCoatingPlantLoadAmount = 0;

      let currentDataIndex;

      for (let row in sheet) {
        const data = sheet[row];

        if (data.w == null) {
          continue;
        }

        if (data.w === 'tn') {
          let temperatureSum = 0;

          for (let i = 0; i < temperature.length - 1; i++) {
            temperatureSum += temperature[i];
          }

          const temperatureAvarage = temperatureSum / (temperature.length - 1);

          const load = {
            time: time[time.length - 1],
            recipe: recipe[currentCoatingPlantLoadAmount],
            temperature: Math.round(temperatureAvarage),
            truck: lastTruck,
            TT: TT,
            site: site,
            mass: Math.round(mass * 100) / 100
          }

          massSum += mass;

          coatingPlantLoads.push(load);
          break;
        }

        if (data.t === 'n' && data.w.includes(':')) {
          const serial = data.v;
          var utc_days = Math.floor(serial - 25569);
          var utc_value = utc_days * 86400;
          var date_info = new Date(utc_value * 1000);
          const timeString = date_info.getFullYear() + '-' + paddedNumber(date_info.getMonth() + 1) + '-' + paddedNumber(date_info.getDate());
          const clockValue = data.w;
          const hours = clockValue.substring(0, clockValue.indexOf(':'));
          const minutes = clockValue.substring(clockValue.indexOf(':') + 1, clockValue.lastIndexOf(':'));
          const seconds = clockValue.substring(clockValue.lastIndexOf(':') + 1, clockValue.lastIndexOf(' '));
          time.push(timeString + 'T' + paddedNumber(hours) + ':' + paddedNumber(minutes) + ':' + paddedNumber(seconds));
          currentDataIndex = 0;
        }
        else if (currentDataIndex === 1) {
          recipe.push(data.w);
        }
        else if (currentDataIndex === 13) {
          temperature.push(data.v);
        }
        else if (currentDataIndex === 14) {
          let truck;

          if (!/^\d+$/.test(data.w.charAt(0))) {
            truck = data.w;
          }
          else {
            truck = '-';
            TT = data.w;
            currentDataIndex++;
          }

          if (lastTruck != null && lastTruck !== truck) {
            const lastTemperature = temperature[temperature.length - 1];
            let temperatureSum = 0;

            for (let i = 0; i < temperature.length - 1; i++) {
              temperatureSum += temperature[i];
            }

            const temperatureAvarage = temperatureSum / (temperature.length - 1);

            const load = {
              time: time[time.length - 2],
              recipe: recipe[currentCoatingPlantLoadAmount],
              temperature: Math.round(temperatureAvarage),
              truck: lastTruck,
              TT: TT,
              site: site,
              mass: Math.round(mass * 100) / 100
            }

            massSum += mass;

            coatingPlantLoads.push(load);
            currentCoatingPlantLoadAmount++;
            temperature = []
            temperature.push(lastTemperature);
            mass = 0;
          }

          lastTruck = truck;
        }
        else if (currentDataIndex === 15) {
          TT = data.w;
        }
        else if (currentDataIndex === 16) {
          if (!isNaN(data.v)) {
            site = '-';
            mass += data.v;
            currentDataIndex++;
          }
          else {
            site = data.w;
          }
        }
        else if (currentDataIndex === 17) {
          mass += data.v;
        }

        if (currentDataIndex != null) {
          currentDataIndex++;
        }
      }

      const sum = {
        time: ' ',
        recipe: ' ',
        temperature: ' ',
        truck: ' ',
        TT: ' ',
        site: 'SUMMA:',
        mass: Math.round(massSum * 100) / 100
      }

      coatingPlantLoads.push(sum);

      this.setState({ coatingPlantLoads: coatingPlantLoads });
      console.log(coatingPlantLoads);
    }.bind(this);

    reader.readAsArrayBuffer(this.state.dataFile);
  }

  clearExcel () {
    this.setState({ coatingPlantLoads: [] });
  }

  render () {
    return (
      <div>
        <div className='container'>
          <div className="center">
            <h1>Syötä asemadata</h1>
          </div>
          <label>
            Data
            <input type='file' accept={''}
              onChange={this.changeState.bind(this, 'dataFile', 'file', null)} />
          </label>
          <button onClick={this.sendCoatingPlantData} disabled={this.state.dataFile == null}>
            Liitä
          </button>
        </div>
        <CombineLoader show={this.state.combining} />
        <Excel show={this.state.coatingPlantLoads.length !== 0}
          toggle={this.clearExcel}
          name={'asema data raportti'}
          headers={['Aika', 'Resepti', 'Lämpötila', 'Rekkari', 'TT-tunnus', 'Kohde', 'Tonnit']}
          dataHeaders={['time', 'recipe', 'temperature', 'truck', 'TT', 'site', 'mass']}
          timeField={'time'}
          data={fromJS(this.state.coatingPlantLoads)} />
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showMessage, showConfirm, showNotice })(InputCoatingPlantData);
