import React from 'react';
import { connect } from 'react-redux';
import { addLoad, removeLoadById, addLoads } from './Actions';
import { DataField, showNotice, showConfirm, showMessage, selectTruck } from 'components';
import { fetch, floatValue, Socket, stateValueParser } from '../utils.js';
import { MapView, Input } from './Components';
import ChangeLoadView from './Components';
import './Load.css';


const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;

const FiveLastLoads = props => {
  if (props.loads.size === 0) return null;

  if (props.loading) return <div className='loader'></div>;

  return (
    <div>
      <h4>5 viimeisintä kuormaa</h4>
      { props.loads.splice(0, props.loads.size-5).map((load) => {
        const date = new Date(load.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes());
        const header =  load.get('truck') + 
          ' | Massa: ' + Math.round(load.get('total_mass') * 1000) / 1000 +
          ' | Laatu: ' + (load.get('quality') || '-') +
          ' | Huomiot: ' + (load.get('attentions') || '-') +
          ' | Luotu: ' + time +
          ' | Käytetty: ' + (load.get('used') ? 'Kyllä' : 'Ei');

        const data = {}

        return (
          <div className='datafield' key={load.get('id')}>
            <DataField header={header} data={data}>
              <div className='row center'>
                <div className='column'>
                  <button onClick={props.changeLoad.bind(this, load)}>
                    Muokkaa
                  </button>
                </div>
                <div className='column'>
                  <button className='button-outline' onClick={props.removeLoad.bind(null, load.get('id'))}>
                    Poista
                  </button>
                </div>
              </div>
            </DataField>
          </div>
        );
      })
      }
    </div>
  );
};


class LoadNew extends React.Component {

  constructor(props) {
    super(props);

    this.watchID = null;

    this.state = {
      truck: null,
      mass: 0,
      quality: '',
      attentions: '',
      latitude: null,
      longitude: null,
      accuracy: null,
      locationTime: null,
      loadingLoads: false,
    };

    this.changeState = this.changeState.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.useLocation = this.useLocation.bind(this);
    this.locationError = this.locationError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goChangeLoad = this.goChangeLoad.bind(this);
    this.clearChangeLoad = this.clearChangeLoad.bind(this);
    this.confirmRemoveLoad = this.confirmRemoveLoad.bind(this);
    this.removeLoad = this.removeLoad.bind(this);
    this.resetTruck = this.resetTruck.bind(this);
  }

  componentWillMount() {
    this.getLoads();

    if (typeof(Storage) !== 'undefined') {
      this.setState({
        mass: floatValue(localStorage.mass, 0),
        quality: localStorage.quality,
        attentions: localStorage.attentions,
      });
    }

    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket('/data/');
      this.socket.onmessage = function(e) {
        const data = JSON.parse(e.data);
        if (data['action'] === 'create') {
          if (data['type'].includes('Mass') && this.props.selectedConstructionSite &&
              data['model']['construction_site']['id'] === this.props.selectedConstructionSite.get('id')) {
            this.props.addMass(data['model']);
          }
        }
        else if (data['action'] === 'update') {
          if (data['type'].includes('Mass') && this.props.selectedConstructionSite &&
              data['model']['construction_site']['id'] === this.props.selectedConstructionSite.get('id')) {
            this.props.changeMass(data['model']);
          }
        }
        else if (data['action'] === 'delete') {
          if (data['type'].includes('Mass') && this.props.selectedConstructionSite &&
              data['model']['construction_site']['id'] === this.props.selectedConstructionSite.get('id')) {
            this.props.removeMassById(data['model']['id']);
          }
        }
      }.bind(this)
    }

    if (!navigator.geolocation) {
      this.props.showMessage('Virhe', 
        'Paikan haku ei ole tuettu tällä selaimella eli et voi saada automaattista sijaintia', 'Error');
      return;
    }

    this.watchID = navigator.geolocation.watchPosition(this.setLocation, this.locationError, 
      {enableHighAccuracy: true});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedTruck != null && this.props.selectedTruck !== nextProps.selectedTruck) {
      this.setState({
        truck: nextProps.selectedTruck.get('register_number'),
        mass: nextProps.selectedTruck.get('default_mass')
      });
    }
    if (nextProps.selectedContract === null || nextProps.selectedConstructionSite === null) {
      this.props.addLoads([]);
      return;
    }

    if (nextProps.selectedConstructionSite == null || this.props.selectedConstructionSite === nextProps.selectedConstructionSite) return;

    this.getLoads(nextProps.selectedConstructionSite.get('id'));
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.watchID);
    if (this.socket != null) this.socket.close();
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value === undefined) {
      return;
    }
    
    this.setState({[propertyName]: value});

    if (typeof(Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  setLocation(position) {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: Math.ceil(position.coords.accuracy)
    });
  }

  useLocation() {
    if (this.state.location_road_part == null || !this.state.autoGPS) return;
    this.setState({
      road_part: this.state.location_road_part,
      road_pole: this.state.location_road_pole
      }, function() {
        localStorage.road_part = this.state.road_part;
        localStorage.road_pole = this.state.road_pole;
    });
  }

  locationError(err) {
    this.setState({
      locationError: true,
    });
  }

  onSubmit() {
    if (this.state.truck === '' && this.props.truck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    if (this.state.mass === 0) {
      this.props.showNotice('Massa määrää ei ole annettu', 'Warning');
      return;
    }

    let date = new Date();

    date.setHours(date.getHours());

    const load = {
      constructionSiteId: this.props.selectedConstructionSite.get('id'),
      truck: this.state.truck,
      total_mass:  parseFloat(this.state.mass),
      quality: this.state.quality || '',
      attentions: this.state.attentions || '',
      date: date.toISOString().replace('Z', '')
    };
    
    fetch('/coatingplantloads/', 'POST', load).then(data => {
      this.props.showNotice('Kuorman lisäys onnistui', 'Ok');
      this.props.selectTruck(null);

      this.setState({
        mass: '',
        quality: '',
        attentions: ''
      });

      localStorage.removeItem("mass");
      localStorage.removeItem("quality");
      localStorage.removeItem("attentions");

      this.props.addLoad(data);
    }).catch(error => {
      if (error.message === '400') {
        this.props.showMessage('Virhe', 'Massan lisäys epäonnistui virheellisen datan vuoksi', 'Error');
      }
      else {
        this.props.showMessage('Virhe', 'Palvelin virhe', 'Error');
      }
    });
  }

  getLoads(constructionSite) {
    this.setState({
      loadingLoads: true
    });

    let url = '/coatingplantloads';

    if (constructionSite != null) {
      url += '/site/' + constructionSite;
    }

    fetch(url).then(data => {
      this.setState({
        networkError: false
      });

      this.props.addLoads(data);
    }).catch(error => {
      this.setState({
        networkError: true
      });
    }).then(() => {
      this.setState({
        loadingLoads: false
      });
    });
  }

  goChangeLoad(loadId) {
    this.setState({
      changingLoad: loadId,
    });
  }

  clearChangeLoad() {
    this.setState({
      changingLoad: null,
    });
  }

  removeLoad() {
    fetch('/coatingplantloads/' + this.state.removingLoad + '/', 'DELETE').then(data => {
      this.props.showNotice('Kuorma poistettu', 'Ok')
      this.props.removeLoadById(this.state.removingLoad);
    }).catch(error => {
      this.props.showMessage('Virhe', 'Kuorman poisto epäonnistui', 'Error');
    });
  }

  confirmRemoveLoad(loadId) {
    this.setState({
      removingLoad: loadId,
    });
    this.props.showConfirm('Poistetaanko kuorma?', this.removeLoad);
  }

  resetTruck() {
    this.props.selectTruck(null);
    localStorage.truck = null;
    this.setState({
      truck: null
    });
  }

  render() {
    return (
      <div className='container'>
        <h1>Kuorman lisäys</h1>
        <Input changeState={this.changeState} mass={this.state.mass}
          quality={this.state.quality} attentions={this.state.attentions}
          truck={this.state.truck} resetTruck={this.resetTruck} store={this.props.store} />
        <button onClick={this.onSubmit}>Lisää kuorma</button>
        <FiveLastLoads loads={this.props.loads}
                       loading={this.state.loadingLoads}
                       removeLoad={this.confirmRemoveLoad}
                       changeLoad={this.goChangeLoad}/>
        <MapView yourLaditude={this.state.latitude} yourLongitude={this.state.longitude}/>
        <ChangeLoadView load={this.state.changingLoad} clear={this.clearChangeLoad} store={this.props.store} />
      </div>
    );
  }
}

export default connect(state => ({
  loads: state.load.get('loads'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
}), { addLoad, removeLoadById, addLoads, showNotice, showConfirm,
      showMessage, selectTruck })(LoadNew);
